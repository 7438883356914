//Reusable Functions for Product

import store from './../store'

export function checkProductPromo(_product) {
    let promotions = store.getters.promotions
    let itemPromoFound = null

    let promoInd
    promotions.forEach((promo, ind) => {
        let findItem = promo.product_promos.find(item => item.product.id == _product.id)
        promoInd = ind
        if(findItem) {
            itemPromoFound = findItem
            promoInd = ind
        }
    })

    //return promo price or else
    if(itemPromoFound)
        return {
            promo_price: itemPromoFound.promo_price,
            promo_badge: promotions[promoInd].badge ? promotions[promoInd].badge.url : null
        }
    else return false
}
// checkBannerPromo: () => {

// }